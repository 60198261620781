import React from "react";
import styled from "@emotion/styled";

import Image from "@components/Image";

import mediaqueries from "@styles/media";
import { IAuthor } from "@types";

import SocialLinks from "@components/SocialLinks";

interface AuthorHeroProps {
  author: IAuthor;
}

const AuthorHero: React.FC<AuthorHeroProps> = ({ author }) => {
  return (
    <Hero>
      <HeroImage>
        <RoundedImage src="https://lh3.googleusercontent.com/i6_ix1YAsk6CrOX75l9d0l_f0uQED-SxsxIt6tDrkZ9ka12jM31NDmiXp9ONrK4Q8iJmOZA9hyvmLkray9HuzRi2GMLXeXGJW24hQYktBMmoz4qCKKDpc6gNfp_8ZxqtpcXdStUWbESs4mqYN5RrBFQlV35tkJjtfix1vvmAPe7joRudRTJGF5yrhc5c7ze1Ft2rWy0LMc1XvrIRP3Ll7X5o6o_w1KUZrP6SZ5VpcfJyfkPz8V-pBKfyDRg0tRu9imjYDV159FgBqZkrT00MCrlZr8Pm9ngM144WmUpTlFe93r0443IE1amlgIow76phC5769n9lA2fb3Y7Ri9w-Jq0TNv7GRU75GXu-BCfknJBWfTn09MxALepLQIINiHPagrg_u10F_A5FrHFVFRZFldSJgn5NZ1xkQKoCnRktjvZWI3eLr-K43TkKOUDb-ZTjpluBnt7ypgr41PwqM8hEG22IUgFzyGMnPhkTn3YqGo3dpb6UW6hX2LEkejAzYVwLaKgAYHkHaUDUUdHoj3l5naN0-cHYlDIbH0st62_4SEYvuiCp1eg-YPBJrx0E0mcVAXDJXTNtlylK207ShxiJY0RlSiSe2hk1hyEOVzgLQfThjsaQ-KFOn-IIMEe1edFbsnWMZOfn5RzVwtW8nEF9OD8UvO_SNA-Msd2h9W_JAARXSausDDUvIfSxeuBTk-vQfXGTKZcCSDStP2GZ6FAhpeHym1bzmoN5KCDlEOWoDE4OfSvPDA=s696-no" />
      </HeroImage>
      <Heading>{author.name}</Heading>
      <Subheading>{author.bio}</Subheading>
      <Social>
        <SocialLinks links={author.social} />
      </Social>
    </Hero>
  );
};

export default AuthorHero;

const Hero = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 35px auto 110px;
`;

const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  height: 164px;
  width: 164px;
  margin-bottom: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${p => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);

  ${mediaqueries.tablet`
    width: 146px;
    height: 146px;
  `}

  ${mediaqueries.phablet`
    width: 136px;
    height: 136px;
    margin-bottom: 25px;
  `}
`;

const RoundedImage = styled(Image)`
  border-radius: 50%;
  width: 100%;
`;

const Heading = styled.h1`
  font-size: 38px;
  font-family: ${p => p.theme.fonts.sansSerif};
  color: ${p => p.theme.colors.primary};
  margin-bottom: 15px;
  font-weight: 600;

  ${mediaqueries.tablet`
  `}

  ${mediaqueries.phablet`
  `}
`;

const Subheading = styled.p`
  margin: 0 auto;
  max-width: 600px;
  color: ${p => p.theme.colors.grey};
  font-size: 18px;
  font-family: ${p => p.theme.fonts.sansSerif};
  line-height: 1.4;
  text-align: center;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`;
